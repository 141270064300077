<template>
  <div>
    <div class="container" v-if="!isShowReport && !isShowAdvice">
      <base-header :info="userInfo"></base-header>
      <div class="sub-content">
        <div class="first-row">
          <div class="left-side">
            当前时间：{{now()}}
          </div>
          <div class="right-side">
            心率信号：
            <Icon type="ios-star-outline" size="18"></Icon>
            <Icon type="ios-star-outline" size="18"></Icon>
            <Icon type="ios-star-outline" size="18"></Icon>
            <Icon type="ios-star-outline" size="18"></Icon>
            <Icon type="ios-star-outline" size="18"></Icon>
          </div>
        </div>
        <div class="first-row">
          <div class="left-side">参数设定：</div>
          <div class="right-side">
            <span>心脏疾病：</span>
            <Select v-model="xzjb" style="width: 100px;" @on-change="getHeartRate">
              <Option value="有">有</Option>
              <Option value="无">无</Option>
            </Select>
            <span>锻炼习惯：</span>
            <Select v-model="dlxg" style="width: 100px;" @on-change="getHeartRate">
              <Option value="有">有</Option>
              <Option value="无">无</Option>
            </Select>
          </div>
        </div>
        <table class="table">
          <tr>
            <th>起（分钟）</th>
            <th>止（分钟）</th>
            <th>阻力等级</th>
            <th>速度下限</th>
            <th>速度上限</th>
          </tr>
          <tr v-for="(item, i) in data" :key="i">
            <td v-for="index in 5" :key="'td' + index">
              <span v-if="i === 2 || index === 1 || index === 2">{{item[index-1]}}</span>
              <Input v-model="item[index-1]" placeholder="请输入" v-else></Input>
            </td>
          </tr>
        </table>
        <div class="chart-panel">
          <p>
            <span>心率信号测试中</span>
            <span style="font-size:14px;color:#fdad00">（运行计时：{{replaceCountdown(countdownTime)}}）</span>
          </p>
          <p style="text-align:right;font-size:14px">
            <a class="icon_edit" href="javascript:void(0)" @click="handleShowModal"></a>
            <span style="vertical-align: middle;">靶心率：{{`${heartRate.bxl_xx}-${heartRate.bxl_sx}`}}</span>
          </p>
          <Modal v-model="showModal" title="心率调整" @on-ok="handleModalOk">
            <table class="table">
              <tr>
                <th>时间（分钟）</th>
                <th>心率（次/分）</th>
              </tr>
              <tr v-for="(item, index) in 14" :key="index" >
                <td>{{ index }}</td>
                <td><Input v-model="manualHeartData[index]" placeholder="请输入"></Input>
                </td>
              </tr>
            </table>
          </Modal>
          <div class="chart" ref="chart"></div>
        </div>
        <table class="table">
          <tr>
            <th>时间（分钟）</th>
            <th>血氧</th>
          </tr>
          <tr>
            <td>0</td>
            <td>
              <Input v-model="oxygen[0]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <Input v-model="oxygen[1]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <Input v-model="oxygen[2]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              <Input v-model="oxygen[3]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              <Input v-model="oxygen[4]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              <Input v-model="oxygen[5]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>
              <Input v-model="oxygen[6]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td>
              <Input v-model="oxygen[7]" placeholder="请输入">
                <span slot="append">%</span>
              </Input>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer need-remove">
        <Button type="success" @click="handleStart">开始测试</Button>
        <Button type="info" @click="handleRestart">取消重来</Button>
        <Button type="error" @click="handleCancel">结束测试</Button>
        <Button type="primary" @click="handleReport">查看报告</Button>
      </div>
    </div>
    <div class="container" v-if="isShowReport">
      <base-header :info="userInfo"></base-header>
      <div class="sub-content">
        <div class="first-row">
          <table class="table">
            <tr>
              <th>时间（分钟）</th>
              <th>血氧</th>
            </tr>
            <tr>
              <td>0</td>
              <td>{{oxygen[0]}}%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{{oxygen[1]}}%</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{{oxygen[2]}}%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>{{oxygen[3]}}%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>{{oxygen[4]}}%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>{{oxygen[5]}}%</td>
            </tr>
            <tr>
              <td>11</td>
              <td>{{oxygen[6]}}%</td>
            </tr>
            <tr>
              <td>13</td>
              <td>{{oxygen[7]}}%</td>
            </tr>
          </table>
          <div class="chart-panel">
            <p>
              <span style="display:block;">心肺耐力靶向力测试</span>
              <span style="float:right;font-size:14px">靶心率：{{`${heartRate.bxl_xx}-${heartRate.bxl_sx}`}}</span>
            </p>
            <p class="chart-subtitle">
              <span>{{ this.xzjb }}心脏疾病</span>
              <span>{{ this.dlxg }}运动习惯</span>
              <span>根据您的身体特征建议您的推荐档位为{{ heartRate.dw }}</span>
            </p>
            <div class="chart" ref="chartReport"></div>
          </div>
        </div>
        <table class="table">
          <tr>
            <td>静息心率</td>
            <td colspan="2">{{reportData.jxxl}}</td>
            <td rowspan="4">次/分</td>
            <td rowspan="2">恢复等级</td>
            <td rowspan="2">{{reportData.hfdj}}</td>
          </tr>
          <tr>
            <td>平台期心率</td>
            <td>&lt;</td>
            <td>{{reportData.ptqxl}}</td>
          </tr>
          <tr>
            <td rowspan="2">靶心率</td>
            <td>上限</td>
            <td>{{reportData.bxl_sx}}</td>
            <td>耐力等级</td>
            <td>{{reportData.nldj}}</td>
          </tr>
          <tr>
            <td>下限</td>
            <td>{{reportData.bxl_xx}}</td>
            <td>氧供等级</td>
            <td>{{reportData.ygdj}}</td>
          </tr>
        </table>
        <div class="report">
          <p class="report-title">测试结果分析：</p>
          <div class="report-content" v-html="replaceBreak(reportData.csjgfx)"></div>
          <div style="display:flex;justify-content: space-between;">
            <p class="report-title">测试时间：{{now().substring(0, 10)}}</p>
            <p class="report-title">测试教练：{{userName}}</p>
          </div>
        </div>
        <div>
          <table class="report-table">
            <tr>
              <td style="width: 120px;"></td>
              <th style="display: flex;justify-content:space-between;align-items:center;"><span>适生建议</span><span>咨询师：<Input style="width: 120px;" :value="userName" /></span></th>
            </tr>
            <tr><th>循律技术</th><td colspan="2"><Input/></td></tr>
            <tr><th>靶向技术</th><td colspan="2"><Input/></td></tr>
            <tr><th>再生技术</th><td colspan="2"><Input/></td></tr>
          </table>
        </div>
      </div>
      <div class="footer need-remove">
        <Button type="primary" @click="onNextPage">下一张</Button>
      </div>
    </div>
    <div class="container" v-if="isShowAdvice">
      <base-header :info="userInfo" title="心肺耐力处方"></base-header>
      <div class="sub-content">
        <div class="comment">
          心肺耐力训练基于美国ACSM运动医学处方，通过安全诱导测试者到达靶心率后观察5-7-9-11-13分钟的心率的恢复情况，可有效评价心功能储备，心肺耐力，循环系统代谢水平及心脑血管疾病风险，是一种非临床非药物使机体的能力得到总体提升的方法。
        </div>
        <div class="chart" ref="chartAdvice"></div>
        <table class="table" style="height: 320px">
          <tr>
            <th colspan="7">
              <div style="display:flex;justify-content:space-around">
                <span>{{userInfo.name}}</span>
                <span>{{userInfo.age}}岁</span>
                <span>{{userInfo.md}}</span>
                <span>出具时间：{{now().substring(0, 10)}}</span>
                <span>方案名称：{{adviceData.famc}}</span>
              </div>
            </th>
          </tr>
          <tr>
            <td colspan="2">时间</td>
            <td>阻力级别</td>
            <td>速度</td>
            <td>每日频次</td>
            <td>每周频次</td>
            <td>调节方式</td>
          </tr>
          <tr>
            <td>预热</td>
            <td>{{adviceData.yr1}}</td>
            <td>{{adviceData.yr2}}</td>
            <td>{{adviceData.yr3}}</td>
            <td rowspan="3">{{adviceData.mrpc}}</td>
            <td rowspan="3">{{adviceData.mzpc}}</td>
            <td rowspan="3">{{adviceData.tjfs}}</td>
          </tr>
          <tr>
            <td>训练</td>
            <td>{{adviceData.xl1}}</td>
            <td>{{adviceData.xl2}}</td>
            <td>{{adviceData.xl3}}</td>
          </tr>
          <tr>
            <td>放松</td>
            <td>{{adviceData.fs1}}</td>
            <td>{{adviceData.fs2}}</td>
            <td>{{adviceData.fs3}}</td>
          </tr>
        </table>
        <div class="report">
          <p class="report-title">注意事项：</p>
          <div class="report-content">
            1.请勿擅自操作，听从教练指导；<br>
            2.请监测自己的心率，使其保持在建议运动安全心率范围内；<br>
            3.在训练过程中如果出现身体不适，请及时休息或者寻求帮助；<br>
            4.训练过程中，避免突然用力，身体避免左右倾斜。<br>
          </div>
        </div>
        <div class="report">
          <p class="report-title">指导意见：</p>
          <div class="report-content" v-html="replaceBreak(adviceData.xffa)"></div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="isShowAdvice">
      <Button type="primary" @click="windowZoomTip">完成报告</Button>
    </div>
  </div>
</template>

<script>
import JsPDF from 'jspdf';
import BaseHeader from './header';
import dayjs from 'dayjs';
import echarts from 'echarts';
import html2canvas from 'html2canvas';
import memberService from '@/services/memberService';

export default {
	components: { BaseHeader },
	props: {
		userInfo: {
			type: Object,
		},
	},
	data() {
		return {
			xzjb: '有',
			dlxg: '有',
			data: [
				[0, 3],
				[3, 4],
				[4, 13, 0, 0, 0],
			],
			oxygen: [],
			chart: null,
			heartRate: {
				bxl_sx: '',
				bxl_xx: '',
				bzxl: [],
			},
			timer: null,
			runTimer: null,
			countdownTime: 0,
			showModal: false,
			trainData: {},
			manualHeartData: [],
			standartData: [],
			highData: [],
			lowData: [],
			speedData: [],
			resistanceData: [],
			heartRateData: [],
			reportData: {},
			adviceData: {},
			isShowReport: false,
			isShowAdvice: false,
			chartOptions: {},
			userName: '',
			testStartTime: '',
		};
	},
	mounted() {
		this.userName = JSON.parse(localStorage.getItem('userInfo'))['user_info']['name'];
		this.chart = echarts.init(this.$refs.chart);
		this.setChartOptions();
		this.chart.setOption(this.chartOptions);
		this.getHeartRate();
	},
	methods: {
		now() {
			return dayjs().format('YYYY-MM-DD HH:mm');
		},
		setChartOptions() {
			this.chartOptions = {
				color: ['#bb3d3d', '#fe9c58', '#609930', '#276caa', '#512BC8', '#f3e1e7', '#f3e1e7'],
				legend: {
					data: ['阻力等级', '心率', '速度', '标准心率', '平台期心率'],
					bottom: 10,
				},
				tooltip: {
					trigger: 'axis',
					formatter: (params) => {
						const xAxisName = params[0].axisValue;
						const tips = params.map((item) => {
							let value = Array.isArray(item.data) ? item.data[1] : item.data;
							if (item.seriesName === '速度') value = ((value - 40) * 10) / 5;
							if (item.seriesName === '阻力等级') value = (value - 40) / 5;
							return `<div>${item.marker}${item.seriesName}：${value}</div>`;
						});
						return `<p>第 ${xAxisName} 分钟</p>${tips.join('')}`;
					},
				},
				xAxis: {
					name: '时间',
					type: 'category',
					data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
				},
				yAxis: {
					name: '心率',
					type: 'value',
					min: 40,
				},
				series: [
					{
						name: '阻力等级',
						data: this.resistanceData,
						type: 'bar',
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'top',
									textStyle: {
										color: '#bb3d3d',
										fontSize: 10,
									},
									formatter: (val) => {
										return (val.data[1] - 40) / 5;
									},
								},
							},
						},
					},
					{
						name: '心率',
						data: this.heartRateData,
						type: 'line',
					},
					{
						name: '速度',
						data: this.speedData,
						type: 'bar',
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'top',
									textStyle: {
										color: '#609930',
										fontSize: 10,
									},
									formatter: (val) => {
										return ((val.data[1] - 40) * 10) / 5;
									},
								},
							},
						},
					},
					{
						name: '标准心率',
						data: this.standartData,
						type: 'line',
					},
					{
						name: '平台期心率',
						data: new Array(16).fill(this.userInfo.ptqxl),
						type: 'line',
						symbol: 'none',
						itemStyle: {
							normal: {
								lineStyle: { width: 2, type: 'dotted' },
							},
						},
					},
					{
						name: '靶心率-下限',
						data: this.highData,
						type: 'line',
						stack: 'total',
						areaStyle: {
							color: 'transparent',
						},
						symbol: 'none',
						tooltip: { show: false },
						markArea: {
							label: { color: '#003366' },
							data: [
								[
									{ name: '提升期', xAxis: 0, itemStyle: { color: '#FF8F5F', opacity: 0.5 } },
									{ xAxis: 3 },
								],
								[
									{ name: '维持期', xAxis: 3, itemStyle: { color: '#C7B8FF', opacity: 0.5 } },
									{ xAxis: 4 },
								],
								[
									{ name: '恢复期', xAxis: 4, itemStyle: { color: '#B5EAFF', opacity: 0.5 } },
									{ xAxis: 15 },
								],
							],
						},
					},
					{
						name: '靶心率-上限',
						data: this.lowData,
						type: 'line',
						stack: 'total',
						areaStyle: {
							color: '#f3e1e7',
						},
						symbol: 'none',
						tooltip: { show: false },
					},
				],
			};
		},
		getHeartRate() {
			const params = {
				member_id: this.userInfo.member_id,
				xzjb: this.xzjb,
				dlxg: this.dlxg,
			};
			memberService.xf1(params).then((data) => {
				this.heartRate = data;
				this.standartData = [];
				this.highData = [];
				this.lowData = [];
				this.data = [
					[0, 3, data.dw, data.sd_xx, data.sd_sx],
					[3, 4, data.dw, data.sd_xx, data.sd_sx],
					[4, 13, 0, 0, 0],
				];
				for (let i = 0; i < data.bzxl.length; i++) {
					this.standartData.push([data.bzxl[i].time, data.bzxl[i].value]);
				}
				for (let i = 0; i < 16; i++) {
					this.highData.push([i, data.bxl_xx]);
					this.lowData.push([i, data.bxl_sx - data.bxl_xx]);
				}
				this.chart.setOption({
					series: [
						{
							name: '标准心率',
							data: this.standartData,
						},
						{
							name: '靶心率-下限',
							data: this.highData,
						},
						{
							name: '靶心率-上限',
							data: this.lowData,
						},
					],
				});
			});
		},
		handleShowModal() {
			if (!this.timer) {
				this.manualHeartData = Object.assign(new Array(14), this.heartRateData);
				this.showModal = true;
			} else {
				this.$Message.error('心率测试已开始，请结束测试后重试');
			}
		},
		handleModalOk() {
			this.heartRateData = this.manualHeartData;
			this.chart.setOption({
				series: [
					{
						name: '心率',
						data: this.heartRateData,
					},
				],
			});
		},
		handleStart() {
			if (
				this.data[0].filter((item) => item !== undefined).length !== 5 ||
				this.data[1].filter((item) => item !== undefined).length !== 5
			) {
				this.$Message.warning('请完成参数设定');
				return;
			}
			clearInterval(this.timer);
			clearInterval(this.runTimer);
			this.countdownTime = 0;
			memberService.xf2({ data: JSON.stringify(this.data) }).then((data) => {
				this.speedData = [];
				this.resistanceData = [];
				for (let i = 0; i < data.sd.length; i++) {
					this.speedData.push([data.sd[i].time, (data.sd[i].value * 5) / 10 + 40]);
				}
				for (let i = 0; i < data.zldj.length; i++) {
					this.resistanceData.push([data.zldj[i].time, data.zldj[i].value * 5 + 40]);
				}
				this.chart.setOption({
					series: [
						{
							name: '速度',
							data: this.speedData,
						},
						{
							name: '阻力等级',
							data: this.resistanceData,
						},
					],
				});
			});
			this.testStartTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
			this.getDataInterval();
			this.startCountdown();
		},
		handleRestart() {
			clearInterval(this.timer);
			clearInterval(this.runTimer);
			this.timer = null;
			this.countdownTime = 0;
			this.data = [
				[0, 3],
				[3, 4],
				[4, 13, 0, 0, 0],
			];
			this.oxygen = [];
			this.$Message.success('数据已重置');
		},
		handleCancel() {
			clearInterval(this.timer);
			clearInterval(this.runTimer);
			this.timer = null;
			this.$Message.success('已结束测试');
		},
		handleReport() {
			if (
				this.data[0].filter((item) => item !== undefined).length !== 5 ||
				this.data[1].filter((item) => item !== undefined).length !== 5
			) {
				this.$Message.warning('请完成参数设定');
				return;
			}
			if (this.heartRateData.length === 0) {
				this.$Message.warning('请完成心率测试');
				return;
			}
			if (this.oxygen.filter((item) => item).length !== 8) {
				this.$Message.warning('请完成血氧记录');
				return;
			}
			clearInterval(this.timer);
			clearInterval(this.runTimer);
			if (this.oxygen.filter((item) => item).length === 8) {
				const params = {
					member_id: this.userInfo.member_id,
					xzjb: this.xzjb,
					dlxg: this.dlxg,
					xysj: JSON.stringify(this.oxygen),
					xlsj: JSON.stringify(this.heartRateData),
					mins: this.trainData.mins,
				};
				memberService.xf3(params).then((data) => {
					this.reportData = data.cssj;
					this.adviceData = data.cfsj;
					this.isShowReport = true;
					this.setChartOptions();
					this.$nextTick(function () {
						echarts.init(this.$refs.chartReport).setOption(this.chartOptions);
					});
				});
			} else {
				this.$Message.error('请输入血氧数据');
			}
		},
		getTrueHeartRate() {
			memberService
				.xfTimer({
					member_id: this.userInfo.member_id,
					test_start_time: this.testStartTime,
				})
				.then((data) => {
					this.trainData = data;
					this.heartRateData = data.show;
					this.chart.setOption({
						series: [
							{
								name: '心率',
								data: this.heartRateData,
							},
						],
					});
				});
		},
		getDataInterval() {
			this.getTrueHeartRate();
			this.timer = setInterval(this.getTrueHeartRate, 30000);
		},
		startCountdown() {
			this.runTimer = setInterval(() => {
				this.countdownTime += 1;
			}, 1000);
		},
		replaceCountdown(val) {
			const minute = Math.floor(val / 60).toString();
			const second = (val % 60).toString();
			return `${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
		},
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
		onNextPage() {
			this.isShowAdvice = true;
			this.$nextTick(function () {
				echarts.init(this.$refs.chartAdvice).setOption(this.chartOptions);
			});
		},
		windowZoomTip() {
			this.$Modal.confirm({
				render: (h) => {
					return [
						h(
							'p',
							{
								style: { fontSize: '16px' },
							},
							'为了保证能够较好的导出报告，你需要确保：',
						),
						h(
							'p',
							{
								style: { marginTop: '15px', fontSize: '16px' },
							},
							'1. 显示器分辨率大于等于 1920 * 1080。',
						),
						h(
							'p',
							{
								style: { marginTop: '15px', fontSize: '16px' },
							},
							'2. 使用现代浏览器的最新版本，如 Chrome。',
						),
						h(
							'p',
							{
								style: { marginTop: '10px', fontSize: '16px', lineHeight: 1.5 },
							},
							'3. 浏览器缩放必须为 100%，可通过浏览器设置或者快捷键 CTRL + 0 重置缩放。',
						),
					];
				},
				onOk: () => {
					this.handleExport();
				},
			});
		},
		handleExport() {
			const loading = this.$loading({
				lock: true,
				text: '正在存储中，请稍等',
				spinner: 'el-icon-loading',
			});
			setTimeout(() => {
				const printTarget = document.querySelectorAll('.container');
				if (printTarget.length > 0) {
					const self = this;
					const needRemove = document.querySelectorAll('.container .need-remove');
					needRemove.forEach((ele) => {
						ele.style.display = 'none';
					});
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					Promise.all(
						Array.from(printTarget).map((item) =>
							html2canvas(item, {
								scale: 1,
								scrollX: 0,
								scrollY: -window.scrollY,
							}),
						),
					).then((canvasList) => {
						for (let i = 0; i < canvasList.length; i++) {
							const canvas = canvasList[i];
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							const pageMargin = 10;
							const pageData = canvas.toDataURL('image/png', 1.0);

							if (i !== 0) pdf.addPage();
							pdf.addImage(pageData, 'png', pageMargin, pageMargin, imgWidth, imgHeight);

							if (i === canvasList.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.userInfo.name}${time}心肺耐力测试报告.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								memberService.uploadReport(formData).then((data) => {
									memberService
										.addReport({
											member_id: self.userInfo.member_id,
											type: 1,
											bgzl: 17,
											check_date: data.check_date,
											photo_id: data.id,
											bgms: '出具报告自动上传',
										})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});

									memberService
										.addReport({
											member_id: self.userInfo.member_id,
											type: 2,
											bgzl: 35,
											check_date: data.check_date,
											photo_id: data.id,
											bgms: '出具报告自动上传',
										})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});
								});

								pdf.save(fileName);
								self.$nextTick(() => {
									loading.close();
								});
							}
						}
					});
				}
			}, 0);
		},
	},
};
</script>

<style lang="less" scoped>
.sub-content {
  background-color: #fff;
  margin-top: 10px;
}
.first-row {
  display: flex;
  justify-content: space-between;
  color: #0062C3;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  .table {
    width: 300px;
  }
  .chart-panel {
    flex: 1;
    margin: 15px 0 15px 15px;
  }
  .chart {
    height: 420px;
  }
}
.table {
  width: 100%;
  margin: 20px 0;
  table-layout: fixed;
  text-align: center;
  font-size: 16px;
  th {
    background-color: #0062C3;
    color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
  }
  td {
    color: #0062C3;
    padding: 5px;
    font-weight: bold;
    border: 1px solid #eee;
  }
}
.report-table {
  width: 100%;
  margin: 15px 0;
  text-align: center;
  font-size: 16px;
  th, td {
    padding: 5px 10px;
    border: #eee 1px solid;
  }
  th {
    color: #0062c3;
  }
}
.chart-panel {
  margin-bottom: 10px;
  border: 1px solid #256BAA;
  border-radius: 4px;
  p {
    text-align: center;
    color: #256BAA;
    font-size: 18px;
    margin: 10px;
  }
  .chart-subtitle {
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}
.footer {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}
.report {
  font-size: 18px;
  margin-bottom: 20px;
  &-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-content {
    margin-left: 2em;
  }
}
.chart {
  height: 520px;
}
.comment {
  border: 1px solid #000;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}
.container {
  margin: 10px;
  padding: 10px;
}
</style>

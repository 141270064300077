<template>
  <div class="container">
    <base-header :info="userInfo"></base-header>
    <div class="content-panel" v-if="!showResult">
      <div class="content">
        <div class="header">
          <p class="title">ABO问卷填写</p>
          <div class="right-side">
            <span>问卷记录：</span>
            <Select @on-change="handleABOChange" style="width: 230px;">
              <Option v-for="item in aboList" :value="item.id" :key="item.id">
                {{ item.bgrq.substring(0, 10) + ' ' + item.bgmc }}
              </Option>
            </Select>
          </div>
        </div>
        <div class="behavior-card">
          <div class="card-title">A.机体能力评估问卷</div>
          <div class="behavior-content">
              <Row class="behavior-content-row">
                  <Col span="16">1.您近一个月静息血压是否超过140/90mmHg？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[0]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">2.您近三个月是否一直在服用降血压或缓解心脏疾患的药物？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[1]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">3.您的静息心率是否>75次/分？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[2]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">4.您是否有一过性眼⿊、头昏或明确诊断有脑中风？（下蹲起⽴）</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[3]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">5.您是否有过胸痛、左肩部疼痛或明确诊断有心肌缺⾎、冠心病？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[4]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">6.您常速步行1-2公⾥，上3楼是否疲劳、胸痛、心悸、心绞痛？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[5]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">7.您常速步行1-2公⾥，上3楼是否胸闷、气促、喘气、呼吸困难？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[6]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">8.您运动时是否感觉呼吸不顺畅或有异响（肺活量的达标率&lt;70%？）</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[7]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">9.您是否近⼀年发⽣过骨痛或骨折？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[8]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="16">10.您近三个⽉是否有关节轻度酸痛不适？</Col>
                  <Col>
                    <RadioGroup v-model="bodyResult[9]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
          </div>
          <div class="behavior-score">
            <span>问卷评分：</span>
            <span>{{bodyScore}}</span>
          </div>
        </div>
        <div class="behavior-card">
          <div class="card-title">B.观念行为评估问卷</div>
          <div class="behavior-content">
              <Row class="behavior-content-row">
                  <Col span="14">1.您是否有主动运动？</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[0]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="14">2.您是否运动前有热⾝的准备活动？</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[1]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="14">3.您是否监测过运动中和运动后的心率？</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[2]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="14">4.您是否在运动后进行全⾝放松活动？</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[3]" class="behavior-radio-section">
                        <Radio label="1" class="radio">是</Radio>
                        <Radio label="0" class="radio">否</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="14">5.您进行大步⾛、单车等中等强度运动的感受：</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[4]" class="behavior-radio-section">
                      <Radio label="1" class="radio">吃力</Radio>
                      <Radio label="2" class="radio">适宜</Radio>
                      <Radio label="3" class="radio">轻松</Radio>
                      <Radio label="0" class="radio">无运动</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
              <Row class="behavior-content-row">
                  <Col span="14">6.您进行哑铃等运动器械或⾝体针对性训练的感受：</Col>
                  <Col>
                    <RadioGroup v-model="optionResult[5]" class="behavior-radio-section">
                      <Radio label="1" class="radio">吃力</Radio>
                      <Radio label="2" class="radio">适宜</Radio>
                      <Radio label="3" class="radio">轻松</Radio>
                      <Radio label="0" class="radio">无运动</Radio>
                    </RadioGroup>
                  </Col>
              </Row>
          </div>
          <div class="behavior-score">
            <span>问卷评分：</span>
            <span>{{optionScore}}</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <Button type="primary" @click="handleSave">下一张</Button>
      </div>
    </div>
    <div class="content-panel" v-else>
      <div class="plan-content-body">
        <div class="level-result-field">
          <img src="@/assets/img/a1b1.jpg" alt="pic">
          <div class="show-result">
            <table class="result-table">
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'A2' ? 'result-table--td-checked': '']">A2</td>
                <td :class="['result-table--td', behavior.fx === 'A2 B1' ? 'result-table--td-checked': '']">A2 B1</td>
                <td :class="['result-table--td', behavior.fx === 'A2 B2' ? 'result-table--td-checked': '']">A2 B2</td>
              </tr>
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'A1' ? 'result-table--td-checked': '']">A1</td>
                <td :class="['result-table--td', behavior.fx === 'A1 B1' ? 'result-table--td-checked': '']">A1 B1</td>
                <td :class="['result-table--td', behavior.fx === 'A1 B2' ? 'result-table--td-checked': '']">A1 B2</td>
              </tr>
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'O' ? 'result-table--td-checked': '']">O</td>
                <td :class="['result-table--td', behavior.fx === 'B1' ? 'result-table--td-checked': '']">B1</td>
                <td :class="['result-table--td', behavior.fx === 'B2' ? 'result-table--td-checked': '']">B2</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">您的心肺耐力训练等级为：</span>
          <span class="level-result_score">{{behavior.fx}}</span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">描述 : </span>
          <span class="level-result-field_text">{{behavior.ms}}</span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">特点 : </span>
          <span class="level-result-field_text">{{behavior.td}}</span>
        </div>
        <div class="level-result-field" style="display:flex;">
          <span class="level-result-field_label" style="margin-right:5px;">重点提示 : </span>
          <span class="level-result-field_text" v-html="replaceBreak(behavior.zdts)"></span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">健康综合能力进阶 : </span>
          <span class="level-result-field_text"> {{behavior.nljj}}</span>
        </div>
      </div>
      <div class="footer need-remove">
        <Button type="primary" @click="handleNextStep">下一张</Button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from './header';
import memberService from '@/services/memberService';

export default {
	components: { BaseHeader },
	props: {
		userInfo: {
			type: Object,
		},
	},
	data() {
		return {
			bodyResult: [],
			optionResult: [],
			aboList: [],
			showResult: false,
			behavior: {},
		};
	},
	created() {
		const params = {
			member_id: this.userInfo.member_id,
			page: 1,
			size: 1000,
		};
		memberService.getReportsList(params).then((data) => {
			this.aboList = data.list.filter((item) => item.bgmc === 'ABO分型问卷调查');
		});
	},
	computed: {
		bodyScore() {
			if (this.bodyResult.length === 0) {
				return 0;
			} else {
				return this.bodyResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
		optionScore() {
			if (this.optionResult.length === 0) {
				return 0;
			} else {
				return this.optionResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
	},
	methods: {
		handleABOChange(val) {
			memberService.handleABO({ jl_id: val }).then((data) => {
				this.bodyResult = data.jtnl_xq.split(',');
				this.optionResult = data.glxw_xq.split(',');
			});
		},
		handleSave() {
			if (
				this.bodyResult.filter((item) => item).length !== 10 ||
				this.optionResult.filter((item) => item).length !== 6
			) {
				this.$Message.warning('请完成问卷调查');
				return;
			}
			const params = {
				member_id: this.userInfo.member_id,
				jtnl: this.bodyResult.join(','),
				glxw: this.optionResult.join(','),
			};
			memberService.handleABO(params).then((data) => {
				this.$Message.success('保存成功');
				this.showResult = true;
				memberService.handleABO(data).then((res) => {
					this.behavior = res.jkxwmbzl;
				});
			});
		},
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
		handleNextStep() {
			this.$emit('next-step', 2);
		},
	},
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  background-color: #fff;
}
.title {
  color: #0062C3;
  font-size: 26px;
  font-weight: bold;
  display: inline-block;
  border-bottom: 4px solid #0062C3;
}
.behavior-card {
  margin: 0 auto 50px;
  font-size: 16px;
  .behavior-content-row {
    margin: 10px 0;
  }
  .card-title {
    color: blue;
    font-weight: 700;
    margin-top: 20px;
  }
  .behavior-radio-section {
    display: flex;
    justify-content: space-evenly;
    .radio {
      font-size: 16px;
    }
  }
  .behavior-score {
    font-size: 18px;
    font-weight: 700;
    color: blue;
    margin: 20px 0;
    span:nth-child(2) {
      padding: 0 15px;
      font-size: 32px;
      border-bottom: solid 2px blue;
    }
  }
  &:nth-child(2) {
    .behavior-radio-section {
      display: block;
      .radio {
        margin-right: 20px;
      }
    }
  }
}
.footer {
  text-align: center;
  padding-bottom: 20px;
}
.plan-content-body {
  width: 70%;
  margin: 0 auto 20px;
}
.level-result-field {
  margin: 15px 0;
  position: relative;
  .level-result_score {
    border-bottom: 3px solid #4472c4;
    font-size: 32px;
    padding: 0 10px;
    font-weight: 700;
    color: #e25127;
  }
  &_label {
    color: #4472c4;
    font-weight: 700;
    font-size: 18px;
  }
  &_text {
    font-size: 18px;
  }
  .show-result {
    position: absolute;
    width: 338px;
    height: 299px;
    top: 46px;
    left: 41px;
    .result-table {
      width: 100%;
      height: 100%;
      table-layout: fixed;
      &--td {
        background-color: #306a81;
        font-size: 24px;
        color: #fff;
        text-align: center;
        border-right: 1px #fff solid;
        border-bottom: 1px #fff solid;
        &-checked {
          background-color: #e55530;
        }
      }
      &--tr:last-child {
        .result-table--td {
          border-bottom: none;
        }
      }
    }
  }
}
.container {
  margin: 10px;
  padding: 10px;
}
</style>

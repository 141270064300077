<template>
  <div class="base-header">
    <div class="first-row">
      <div class="left-side"> {{title}} </div>
      <div class="right-side">
        <p>门店：{{info.md}}</p>
        <p>测试日期：{{info.date}}</p>
      </div>
    </div>
    <table class="table">
      <tr>
        <th>姓名</th>
        <th>性别</th>
        <th>年龄</th>
        <th>联系电话</th>
      </tr>
      <tr>
        <td>{{info.name}}</td>
        <td>{{info.sex_show}}</td>
        <td>{{info.age}}</td>
        <td>{{info.mobile}}</td>
      </tr>
    </table>
    <table class="table">
      <tr>
        <th>身高</th>
        <th>体重</th>
        <th>心率</th>
        <th>血压</th>
        <th>腰围</th>
        <th>肺活量</th>
      </tr>
      <tr>
        <td>{{info.sg}}cm</td>
        <td>{{info.tz}}Kg</td>
        <td>{{info.xl}}</td>
        <td>{{info.xy}}mmHg</td>
        <td>{{info.yw}}</td>
        <td>{{info.fhl}}</td>
      </tr>
    </table>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
	props: {
		info: {
			type: Object,
			defalut: {},
		},
		title: {
			type: String,
			default: '心肺耐力测试',
		},
	},
};
</script>

<style lang="less" scoped>
.first-row {
  color: #0062C3;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  .left-side {
    font-size: 24px;
    &::before {
      content: '';
      display: inline-block;
      border-top: 12px solid transparent;
      border-right: 12px solid #0062C3;
      border-bottom: 12px solid #0062C3;
      border-left: 12px solid transparent;
    }
  }
  .right-side {
    text-align: right;
    p:first-child {
      font-size: 16px;
    }
    p:last-child {
      font-size: 14px;
    }
  }
}
.table {
  width: 100%;
  margin-top: 10px;
  table-layout: fixed;
  text-align: center;
  th {
    background-color: #0062C3;
    color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
  }
  td {
    color: #0062C3;
    padding: 5px;
    font-weight: bold;
    border: 1px solid #eee;
  }
}
</style>

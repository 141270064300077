<template>
  <div class="container">
    <base-header :info="userInfo"></base-header>
    <div class="content">
      <p class="title">心肺耐力靶向测试知情同意书</p>
      <p>1、心肺耐力靶向测试的作用：发现问题，提供更有效的方法。有助减缓衰老带来的运动能力下降，控制慢性疾病，减少躯体残疾的风险，延长寿命。促进心理和认知能力的建全。增加幸福感，娱乐和体力活动的能力，减缓焦虑和抑郁，改善认知功能，增强老年人的体质和独立生活的能力。减少老年人摔倒或因摔倒而受伤的风险。</p>
      <p>2、参与者应知道自身的身体情况和曾经经历过心脏相关病史，及时报告你在测试中出现的不适情况和身体异常。有义务提供疾病史以及目前使用的临床药物的治疗记录。必须配合健康教练的相关训练指导。</p>
      <p>3、在测试过程中您有任何疑问或者顾虑，请咨询我们的健康教练，并进一步得到解释。</p>
      <p>4、测试过程中可能出现血压异常，头晕，心率加快或者心律不齐，以及心脏，脑卒中和猝死等罕见情况。健康教练会在测试中仔细观察，最大限度的降低风险，心肺耐力靶向测试现场有相应的急救设备和接受过训练的专业人员以及保证及时处理异常情况。</p>
      <p>5、本次测试的数据记录，仅作为针对您身体训练的依据，不做其他参考的用途。</p>
      <p>6、我同意参加心肺耐力靶向测试，确定我的运动能力和心血管健康状况，我承诺参加这个运动测试是自愿的，如果我要求停止，运动可随时终止。</p>
      <p style="font-weight:bold;">7、心肺耐力靶向测试禁忌症：近半年有手术史，心脏有安装支架、搭桥和起搏器的人群；严重心脏病伴心率失常的人群；有心梗和心绞痛病史人群；有哮喘发作期和呼吸困难的人群；近2周有关节疼痛发作病史；严重关节变形畸形，关节腔积液人群；脊柱弯曲人群；</p>
      <p>8、总结：我已经阅读并充分理解这份知情同意书，清楚心肺耐力靶向测试的程序和可能出现的风险。我有随时提问的机会直至获得满意的服务，我自愿参加这项测试。</p>
    </div>
    <div class="sign-image space20 need-remove">
      <Upload
        action="/api/check/photo/upload"
        :data="{name: userInfo.name+'心肺耐力知情书签字.jpg'}"
        name="photo"
        :show-upload-list="false"
        :max-size="1024"
        :format="['png','jpg','jpeg']"
        :on-format-error="handleFormatError"
        :on-success="handleUploadSuccess"
        :on-exceeded-size="handleMaxSize"
      >
        <Button type="success">上传知情同意书</Button>
      </Upload>
      <a v-if="signSrc" :href="signSrc" target="_blank">查看知情同意书</a>
    </div>
    <div class="sign">
      <span class="space20">测试者签名：{{userInfo.name}}</span>
      <span class="space20">日期：{{userInfo.date}}</span>
    </div>
    <div class="footer need-remove">
      <Button type="primary" @click="handleNextStep">下一张</Button>
    </div>
  </div>
</template>

<script>
import BaseHeader from './header';

export default {
	components: { BaseHeader },
	props: {
		userInfo: {
			type: Object,
		},
	},
	data() {
		return {
			signSrc: '',
		};
	},
	methods: {
		handleNextStep() {
			this.$emit('next-step', 1);
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，文件大小不能超过 1M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg 或 jpeg 格式的文件。',
			);
		},
		handleUploadSuccess(response) {
			if (response.c === 0) {
				this.signSrc = response.data.photo;
			} else {
				this.$Message.error('上传失败，请重试');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content {
  background-color: #fff;
}
.title {
  color: #0062C3;
  font-size: 26px;
  font-weight: bold;
  display: inline-block;
  border-bottom: 4px solid #0062C3;
  margin-bottom: 20px;
}
p:not(.title) {
  margin-bottom: 15px;
  font-size: 20px;
  text-indent: 2em;
}
.sign, .sign-image {
  text-align: right;
  font-size: 20px;
  margin-bottom: 10px;
}
.space20 {
  margin-right: 20px;
}
.footer {
  text-align: center;
  padding-bottom: 20px;
}
.container {
  margin: 10px;
  padding: 10px;
}
</style>

<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
              <span class="sub-label">省份：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.pid"
                        placeholder="请选择..."
                        @on-change="changePro">
                  <Option :value="v.id"
                          v-for="v in proList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">城市：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.cid"
                        placeholder="请选择..."
                        @on-change="changeCity">
                  <Option :value="v.id"
                          v-for="v in cityList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">门店：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="reportData.sid"
                        placeholder="请选择..."
                        @on-change="changeStore">
                  <Option :value="v.id"
                          v-for="v in storeList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">会员：</span>
              <div class="sub-content">
                <Select clearable
                  v-model="uid"
                  placeholder="请选择..."
                  filterable>
                  <Option :value="v.id"
                          v-for="v in userList"
                          :key="v.id">{{v.true_name}}</Option>
                </Select>
              </div>
            </Col>
          </Row>
          <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">城市：</span>
              <div class="sub-content">
                <Input disabled v-model="cityName"></Input>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">门店：</span>
              <div class="sub-content">
                <Input disabled v-model="sName"></Input>
              </div>
            </Col>
            <Col span="5" offset="1">
              <span class="sub-label">会员：</span>
              <div class="sub-content">
                <Select clearable
                  v-model="uid"
                  placeholder="请选择..."
                  filterable>
                  <Option :value="v.id"
                          v-for="v in userList"
                          :key="v.id">{{v.true_name}}</Option>
                </Select>
              </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="报告时间">
          <Date-picker
            format="yyyy-MM-dd"
            type="daterange"
            placeholder="选择日期"
            @on-change="handleChangeDate"
            style="width:300px;margin-right:10px;"></Date-picker>
          <Button type="primary" @click="handleMemberChange">搜索</Button>
        </Form-item>
      </Form>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">心肺耐力测试报告</h2>
      <div class="report-body">
        <Table
          :columns="reportColumns"
          :data="reportList"
          :height="220"
        ></Table>
        <div>
          <span class="records">共 {{reportTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="reportTotal"
              :current="reportCurrentPage"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">心肺耐力训练</h2>
      <div class="content-body" v-if="!userInfo" style="text-align:center;font-size:18px;padding: 10px;">
        请选择会员
      </div>
      <div class="content-body" v-else>
        <section-1 :user-info="userInfo" @next-step.once="handleNextStep"></section-1>
        <section-2 :user-info="userInfo" @next-step.once="handleNextStep" v-if="steps > 0"></section-2>
        <section-3 :user-info="userInfo" @next-step.once="handleNextStep" v-if="steps > 1"></section-3>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="补充用户基本信息"
      :closable="false"
      :mask-closable="false"
    >
      <table class="table">
        <tr>
          <td width="160">姓名</td>
          <td>{{ basicInfo.name }}</td>
        </tr>
        <tr>
          <td>身高</td>
          <td>
            <Input v-model="basicInfo.sg" placeholder="请输入">
              <span slot="append">cm</span>
            </Input>
          </td>
        </tr>
        <tr>
          <td>体重</td>
          <td>
            <Input v-model="basicInfo.tz" placeholder="请输入">
              <span slot="append">Kg</span>
            </Input>  
          </td>
        </tr>
        <tr>
          <td>心率</td>
          <td><Input v-model="basicInfo.xl" placeholder="请输入"></Input></td>
        </tr>
        <tr>
          <td>血压</td>
          <td>
            <div style="display:flex;">
              <Input v-model="basicInfo.ssy" placeholder="收缩压">
                <span slot="append">mmHg</span>
              </Input>
              &nbsp;&nbsp;
              <Input v-model="basicInfo.szy" placeholder="舒张压">
                <span slot="append">mmHg</span>
              </Input>
            </div>
          </td>
        </tr>
        <tr>
          <td>腰围</td>
          <td><Input v-model="basicInfo.yw" placeholder="请输入"></Input></td>
        </tr>
        <tr>
          <td>肺活量</td>
          <td><Input v-model="basicInfo.fhl" placeholder="请输入"></Input></td>
        </tr>
      </table>
      <div slot="footer">
        <Button type="primary" @click="handleModalOk">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import Section1 from './section1';
import Section2 from './section2';
import Section3 from './section3';
import comparisonService from '@/services/comparisonService';
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';
import dayjs from 'dayjs';

export default {
	components: { Section1, Section2, Section3 },
	data() {
		return {
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			userInfo: null,
			steps: 0,
			showModal: false,
			basicInfo: {
				member_id: '',
				name: '',
				sg: '',
				tz: '',
				xl: '',
				ssy: '',
				szy: '',
				yw: '',
				fhl: '',
			},
			reportColumns: [
				{ title: '门店', key: 'md', align: 'center' },
				{ title: '顾客姓名', key: 'gkmc', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '出具日期', key: 'cjrq', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('div', [
							h('a', {
								class: 'icon_eye',
								attrs: {
									href: url,
									target: '_blank',
								},
							}),
							h('Icon', {
								attrs: {
									type: 'md-arrow-round-down',
									size: 22,
									color: '#fdad00',
								},
								style: { cursor: 'pointer' },
								on: {
									click: () => {
										const a = document.createElement('a');
										a.href = url;
										a.style.display = 'none';
										a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
									},
								},
							}),
						]);
					},
				},
			],
			reportList: [],
			reportTotal: 0,
			reportCurrentPage: 1,
			reportData: {
				type: 1,
				month_flag: 0,
				pic_type: 17,
				page: 1,
				size: 30,
				pid: '',
				cid: '',
				sid: '',
			},
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.reportData.sid = this.branch_id[2];
					this.reportData.cid = this.branch_id[1];
					this.reportData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.getReportData();
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.reportData.pid];
			this.reportData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.reportData.cid];
			this.reportData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			this.uid = '';
			if (this.reportData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.reportData.pid,
					cid: this.reportData.cid,
					sid: this.reportData.sid,
					simple_query: 1,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		handleChangeDate(value) {
			this.reportData.start_time = value[0];
			this.reportData.end_time = value[1];
		},
		handleMemberChange() {
			this.userInfo = null;
			this.basicInfo = {
				member_id: '',
				sg: '',
				tz: '',
				xl: '',
				xy: '',
				yw: '',
				fhl: '',
			};
			this.steps = 0;
			this.getReportData();
			if (this.uid) {
				memberService.getMemberInfo({ member_id: this.uid }).then((data) => {
					this.userInfo = { ...data, date: dayjs().format('YYYY-MM-DD') };
					const { member_id, name, sg, tz, xl, xy, yw, fhl } = this.userInfo;
					this.basicInfo = { member_id, name, sg, tz, xl, xy, yw, fhl };
					if (Object.values(this.basicInfo).some((item) => !item)) {
						this.showModal = true;
					}
				});
			}
		},
		handleNextStep() {
			this.steps += 1;
		},
		handleModalOk() {
			if (
				this.basicInfo.sg &&
				this.basicInfo.tz &&
				this.basicInfo.xl &&
				this.basicInfo.ssy &&
				this.basicInfo.szy
			) {
				memberService.postCustomerData(this.basicInfo).then(() => {
					this.showModal = false;
					const xy = `${this.basicInfo.ssy}/${this.basicInfo.szy}`;
					this.userInfo = { ...this.userInfo, ...this.basicInfo, xy };
				});
			} else {
				this.$Message.error('请补全客户基本信息');
			}
		},
		getReportData() {
			if (this.uid) {
				this.reportData.member_id = this.uid;
			}
			memberService.getReportList(this.reportData).then((data) => {
				this.reportList = data.list;
				this.reportTotal = data.row_size;
				this.reportCurrentPage = +data.cur_page;
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportData();
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}

.table {
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;
  text-align: center;
  th {
    background-color: #0062C3;
    color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
  }
  td {
    color: #0062C3;
    padding: 5px;
    height: 40px;
    font-weight: bold;
    border: 1px solid #eee;
  }
}

.report-body {
  margin: 10px;
}
</style>
